.left_border {
    border-left: 7px solid #3719EB;
    position: absolute;
    top: 50%;
    bottom: 0;
}

.right_border {
    border-left: 7px solid #3719EB;
    position: absolute;
    top: 0;
    left: 100%;
    bottom: 50%;
}

.up_border {
    border-top: 7px solid #3719EB;
    position: absolute;
    top: 0;
    left: 50%;
    right: 0;
}

.bottom_border {
    border-top: 7px solid #3719EB;
    position: absolute;
    bottom: 0;
    right: 50%;
    left: 0;
}
