.about_us_background {
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('../../public/bg2.jpg');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
}

.contact_background {
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('../../public/tvrde3.jpg');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
}

.image_background {
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('../../public/mekke2.jpg');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
}

.gradient-to-center {
    background: linear-gradient(to right, #E7E7E7 0%, #FFFFFF 50%, #E7E7E7 100%);
}